import React from 'react';
import SectionTitle from '../components/common/SectionTitle';
import Footer from '../layout/Footer/Footer';
import Navbar from '../layout/Header/Navbar';

const Faq = () => {
  return (
    <>
    <Navbar />
      <section className='faq-section ptb-120 bg-light'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12'>
              <SectionTitle subtitle='FAQ' title='Frequently Asked Questions' description='Here are some of the question that is frequently asked.' centerAlign/>
              
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-12'>
              <div className='accordion faq-accordion' id='accordionExample'>       
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-2'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-2'
                      aria-expanded='false'
                    >
                      How many children can it admit at once?
                    </button>
                  </h5>
                  <div
                    id='collapse-2'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-2'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                     As many children as you wish
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-3'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-3'
                      aria-expanded='false'
                    >
                      How can I access the management software or portal?
                    </button>
                  </h5>
                  <div
                    id='collapse-3'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-3'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      You can get the software by completing and submitting the (Request Demo Form)
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-4'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-4'
                      aria-expanded='false'
                    >
                      Is there a free version?
                    </button>
                  </h5>
                  <div
                    id='collapse-4'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-4'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Yes there is an absolutely free version with no hidden cost or charges but only exist on the windows desktop operating system.
                    </div>
                  </div>
                </div>
                <div className='accordion-item border border-2'>
                  <h5 className='accordion-header' id='faq-5'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapse-5'
                      aria-expanded='false'
                    >
                      How many softwares are required to make it work.
                    </button>
                  </h5>
                  <div
                    id='collapse-5'
                    className='accordion-collapse collapse'
                    aria-labelledby='faq-5'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                     Just two. The windows desktop management application and the windows desktop kiosk application.
                    </div>
                  </div>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Faq;
