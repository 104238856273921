import React from 'react';

const Contact = () => {
  return (
    <>
      <section className='ptb-100'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6 mt-4 mt-lg-0'>
              <div className='p-5 bg-white custom-shadow text-center d-flex flex-column h-100'>
                <span className='fa-solid fa-location-dot fa-3x text-primary'></span>
                <div className='mb-4'>
                  <h5>Visit Us</h5>
                  <p>6137 Crawfordsville Rd Suite F, #361 Speedway, Indiana 46224 USA.</p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mt-4 mt-lg-0'>
              <div className='p-5 bg-white custom-shadow text-center d-flex flex-column h-100'>
                <span className='fa-solid fa-paper-plane fa-3x text-primary'></span>
                <div className='mb-4'>
                Send Us an email and you should receive a response within 24 hours
                </div>
                <span className='btn mt-auto'>
                <a href="mailto:info@iregisterkids.com" className="remove-underscore">info@iregisterkids.com</a>
                </span>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mt-4 mt-lg-0'>
              <div className='p-5 bg-white custom-shadow text-center d-flex flex-column h-100'>
                <span className='fa-solid fa-phone-volume fa-3x text-primary'></span>
                <div className='mb-4'>
                Give us a call
                Reach out to us via these call lines from monday to friday 9am to 5pm.
                </div>
                <span className='btn mt-auto'>
                <a  href="tel:+13174562893" className="remove-underscore">+1 (317)-456-2893</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
