import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import Price from '../components/Price';
import Footer from '../layout/Footer/Footer';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const Pricing = () => {
  return (
    <Layout>
      <PageMeta title='Pricing' />
      <Navbar />
      <div style={{
            background:
              "url('assets/img/pricing.jpg')no-repeat center center / cover", color:'#ffffff'
          }}>
      <PageHeader
        title='Pricing Packages'
        desc='Review our three packages and select the one that’s right for your current requirements. It’s easy to upgrade as your business goes up and up!'
      />
      </div>
      <Price paddingTop='ptb-100'/>
      <Footer />
    </Layout>
  );
};

export default Pricing;
