 const PromoVideo = () => { 
  return (
    <>
      <section className='video-promo-with-icon mt-5 mb-5'>
        <div className='container d-flex justify-content-center'>
        
        <div className='golden-border container min-height' >
        <iframe frameBorder="0" title='iRegisterkids Demo'  scrolling="no" width="100%" height="100%" type="text/html" src="https://www.youtube.com/embed/d0d5_GkWmg8?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=http://youtubeembedcode.com"><div><small><a href="https://youtubeembedcode.com/es/">youtubeembedcode.com/es/</a></small></div><div><small><a href="https://spelstopp.net/">spelstopp.net</a></small></div><div><small><a href="https://youtubeembedcode.com/de/">youtubeembedcode.com/de/</a></small></div><div><small><a href="https://casinoutansvensklicensmedbrite.se/">casinoutansvensklicensmedbrite</a></small></div></iframe>
        
        </div>
        </div>
      </section>
    </>
  );
};

export default PromoVideo;
