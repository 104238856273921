import React from 'react';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 bg-purple text-white'
        style={{
          background:
            "url('assets/img/banner_bg.png')no-repeat center center / cover",
        }}
      >
        <div className='container'>
          <div className='row justify-content-xl-between align-items-center'>
            <div className='col-lg-5 col-xl-5 col-xl-5 col-md-10'>
              <div className='hero-content-wrap mt-5 mt-lg-0 mt-xl-0'>
                <h5 style={{color:'#FF6321'}}>Check In & Check Out System</h5>
                <Fade up>
                <h1 className='fw-bold display-6'>
                Secure and easy check-in and check-out system.
                </h1>
                <p className='lead'>
                For childcare centers, elementary schools and other organizations that wants to keep track of kids attendance
                </p>
                </Fade>
                <Fade down>
                <div className='action-btns mt-5'>
                  <Link to='/request-for-demo' className='btn btn-primary'>
                    Request Demo
                  </Link>
                </div>
                </Fade>
              </div>
            </div>
            <Fade right>
            <div className='col-lg-7 col-md-8 mt-5'>
                <img
                  src='assets/img/banner-final.png'
                  alt='app screen'
                  className='img-fluid'
                />
            </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
