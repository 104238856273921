import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OffCanvasMenu from "./OffCanvasMenu";

const Navbar = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header
        className={`main-header ${navDark ? "position-absolute" : ""} w-100 `}
      >
        <nav
          className={`navbar navbar-expand-xl ${
            navDark ? "navbar-dark" : "navbar-light"
          } sticky-header ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img
                src="assets/img/iregisterkids-white.png"
                alt="logo"
                className="img-fluid logo-white"
              />
              <img
                src="assets/img/iregisterkids.png"
                alt="logo"
                className="img-fluid logo-color"
              />
            </Link>

            <Link
              className="navbar-toggler position-absolute right-0 border-0"
              to={process.env.PUBLIC_URL + "#offcanvasWithBackdrop"}
              role="button"
            >
              <span
                className="far fa-bars"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
              ></span>
            </Link>

            <div style={{justifyContent: 'right'}} className="collapse navbar-collapse">
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                <li>
                  <Link
                    to={process.env.PUBLIC_URL + "/about-us"}
                    className="nav-link"
                  >
                    ABOUT
                  </Link>
                </li>
                <li>
                  <Link
                    to={process.env.PUBLIC_URL + "/pricing"}
                    className="nav-link"
                  >
                   PRICING
                  </Link>
                </li>
                <li>
                  <Link
                    to={process.env.PUBLIC_URL + "/faq"}
                    className="nav-link"
                  >
                   FAQ
                  </Link>
                </li>
              </ul>
            </div>

            <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">

              <Link
                to={process.env.PUBLIC_URL + "/contact-us"}
                className="btn btn-primary"
              >
              Contact Us
              </Link>
            </div>
            <OffCanvasMenu />
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
