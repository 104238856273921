import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfUseDetails = () => {
  return (
    <>
      <section className='ptb-120'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-4 col-md-4 d-none d-md-block d-lg-block'>
              <div className='sticky-sidebar'>
                <Link to='/' className='btn btn-primary mb-4 btn-sm'>
                  <i className='far fa-angle-left me-2'></i> Go Back
                </Link>
               
                <div className='bg-light p-5 mt-4 rounded-custom'>
                  <Link
                    to='/contact-us'
                    className='text-decoration-none text-muted d-flex align-items-center py-2'
                  >
                    <div className='quick-support-icon rounded-circle bg-success-soft me-3'>
                      <i className='far fa-ballot-check text-success'></i>
                    </div>
                    <div className='contact-option-text'>
                   Contact Us
                    </div>
                  </Link>
                  <Link
                    to='#'
                    className='text-decoration-none text-muted d-flex align-items-center py-2'
                  >
                    <div className='quick-support-icon rounded-circle bg-primary-soft me-3'>
                      <i className='far fa-envelope text-primary'></i>
                    </div>
                    <div className='contact-option-text'>
                      <a href="mailto:info@iregisterkids.com" className="remove-underscore">info@iregisterkids.com</a>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-8 col-md-8 p-lg-5'>
              <div>
                <h4 className='mb-4 fw-bold'>
                Terms of Service
                <br />
                1. Acceptance of the Terms of Service
                </h4>
                <p>
                iRegisterkids provides this web site and the services provided through
                 this web site are subject to these terms and conditions of use.
                  The Terms of Service apply to your use of www.iregisterkids.com,
                including any content, functionality and services offered on or via
                this web site and any successor web sites or related applications.
                <br />
                <br />
                Kindly read the Terms of Service carefully before using iRegisterkids,
                 because by using the Website you accept and agree to be bound and abide
                  by these Terms of Service.
                Should you disagree with some of the provisions herein, 
                you can either leave the Website or contact us at info@iregisterkids.com. 
                iRegisterkids is all about capturing, sharing, and reliving life's special moments and connecting parents and children everywhere, and we'll be happy to hear your comments and suggestions.
                </p>
               
                  <h4 className='mb-4 fw-bold'>
                  2. Changes to the Terms of Service and the Website
                  </h4>
                  <p>
                iRegisterkids will always be a work in progress as we constantly 
                make changes and improvements to serve you better.
                We also reserve the right to update the Website and these
                Terms of Service from time to time, at our discretion and 
                without notice. Nevertheless, this document is public on the
                 Website, and you will be able to track the changes for any 
                 new version. Your continued use of the Website following
                  the publishing of updated Terms of Service means that you 
                  accept and agree to the changes.
                  </p>

                <h3 className='mb-4 fw-bold'>
                3. Accessing the Website, Security and Privacy  
                  </h3>
                  <p>
                  We take reliability and security seriously and put significant effort
                   into ensuring that our service operates all the time, and that it is 
                   a secure environment for your data. However, no system is perfectly 
                   secure or reliable, and the reliability of hosting services, Internet 
                   intermediaries, your Internet service provider, and other service providers
                    cannot be assured. When you use iRegisterkids you accept these risks.
                  We are always working hard on improving iRegisterkids, but we can never 
                  guarantee that the Website will be up and running 24/7.
                  <br />
                  <br />
                   We also reserve the right to suspend or restrict access to some features. In any case, 
                  we will not be liable if for any reason all or any part of the Website 
                  is unavailable at any time or for any period, nor for any data loss (see also section 7 below).
                  To access certain features of the Website you have to register by entering your email
                   and choosing a password as part of our security procedures. You must 
                   treat such information as confidential, not disclosing it to any third
                    party and only using the Website in person. 
                    <br />
                    <br />
                    There is a password reset procedure in case you forget your password, but please notify us of 
                     any breach of security. We highly recommend that you choose a strong 
                     password and that you log out from your account at the end of every session. 
                     If you register for the Website, you agree to accept all risks of unauthorized 
                     access to your account and any other information you provide to iRegisterkids.
                      You are responsible for all activity on your iRegisterkids account.
                  It is a condition of your use of the Website that all the information you
                   provide on the Website is correct, current and complete.
                   <br />
                   <br />
                    In the future, you may be asked to provide certain registration details or other information.
                     As custom for internet websites, we reserve the right to disable any 
                     user account, at any time in our sole discretion for any or no reason,
                      including, if in our opinion you have failed to comply with any provision of these Terms of Service.
                  We also do not guarantee that the Website or any content provided on 
                  the Website is error free. We manage your personal data according to our Privacy Policy 
                  </p>
                  <h3 className='mb-4 fw-bold'>
                  4. Intellectual Property Rights and Use Guidelines
                  </h3>
                  The Website features and functionality (including look and feel!), 
                  are owned by iRegisterkids and are protected by Canada and international
                   copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.
                  For purposes of these Terms of Service, the term “Content” includes, 
                  without limitation, information, data, text, photographs, videos, audio
                   clips, written posts and comments, software, scripts, graphics, and interactive
                    features generated, provided, or otherwise made accessible on or through 
                    the Website. For the purposes of these Terms of Service, “Content” also includes all User Content (as defined below).
                  All Content added, created, uploaded, submitted, distributed, or posted 
                  to the Website or by the Website by users (collectively “User Content”),
                   whether publicly posted or privately transmitted, is the sole responsibility 
                   of the person who originated such User Content.
                   <br />
                   <br />
                    You represent that all UserContent provided by you is in compliance with all applicable laws, rules and 
                    regulations. You acknowledge that all Content, including User Content, accessed 
                    by you using the Website is at your own risk.
                  The Website may contain Content specifically provided by us, our partners
                   or our users and such Content is protected by copyrights, trademarks, 
                   service marks, patents, trade secrets or other proprietary rights and laws. 
                   You shall abide by and maintain all copyright notices, information, and restrictions
                    contained in any Content accessed through the Website.
                    <br />
                   <br />
                  We do not guarantee that any Content will be made available on the Website.
                   We reserve the right to, but do not have any obligation to, (i) remove, edit
                    or modify any Content in our sole discretion, at any time, without notice to
                     you and for any reason (including, but not limited to, upon receipt of claims
                      or allegations from third parties or authorities relating to such Content or if
                       we are concerned that you may have violated these Terms of Service), or for no 
                       reason at all and (ii) to remove or block any Content from the Website.

                  <p>

                  </p>
                  <h3 className='fw-bold'>
                3. Accessing the Website, Security and Privacy  
                  </h3>
                  <p>
                  iRegisterkids contains user generated content, and also may 
                  contain message boards, personal web pages, forums or other 
                  interactive features that allow you to upload, post, submit, 
                  publish, display or transmit to other Users Content on or through 
                  the Website. All User Content must be lawful, not spammy,
                   and clear of virus or other malware. More specifically, but 
                   without limiting the foregoing, 
                  </p>
                <div>
                  <h3 className='h5'>
                  it must comply with the following Content Standards:
                  </h3>
                  <ul className='content-list list-unstyled'>
                    <li>
                    Not contain any material which is defamatory, obscene, indecent, abusive, 
                    offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.
                    </li>
                    <li>
                    Not promote sexually explicit or pornographic material, violence, or discrimination based
                     on race, sex, religion, nationality, disability, sexual orientation or age.
                    </li>
                    <li>
                    Not infringe any patent, trademark, trade secret, copyright or
                     other intellectual property rights of any other person.
                    </li>
                    <li>
                    Not violate the legal rights (including the rights of publicity and privacy) 
                    of others or contain any material that could give rise to any civil or criminal
                     liability under applicable laws or regulations or that otherwise may
                      be in conflict with these Terms of Service
                    </li>
                    <li>
                    Not be likely to deceive any person.
                    </li>
                    <li>
                    Not promote any illegal activity, or advocate, promote or assist any unlawful act.
                    </li>
                    <li>
                    Not cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.
                    </li>
                    <li>
                    	Not be used to impersonate any person, or to misrepresent your identity or affiliation with any person or organization
                    </li>
                    <li>
                    Not involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.
                    </li>
                    <li>
                    	Not give the impression that they emanate from us or any other person or entity, if this is not the case.
                    </li>
                    <li>
                      Interface patterns is better for UX than reinventing the
                      wheel
                    </li>
                  </ul>
                  <p>We have a zero-tolerance policy when it comes to objectionable content.
                     Any content that we feel is objectionable or that our users have identified
                      as objectionable will be removed and the user who has provided 
                      the objectionable content will be ejected from the site.
                     Our promise to our users is that we will act on objectionable content reports within 24 hours.</p>
                </div>
                <h3 className='fw-bold'>
                6. Law and Copyright Infringement 
                  </h3>
                  <p>
                  We do not undertake to review all material before it is posted on the Website,
                   and cannot ensure prompt removal of objectionable material after it has been posted.
                    Accordingly, we assume no liability for any action or inaction regarding transmissions,
                     communications or content provided by any user or third party. In the unlikely 
                     event we receive a disclosure request from an authorized party, we reserve 
                     the right to disclose user identities when required to do so by law,
                      including in response to a law enforcement request supported by a
                       valid court order. You waive and hold harmless the Company from any 
                       claims resulting from any action taken by the Company during or as 
                       a result of its investigations and from any actions taken as a consequence of investigations
                    by either the Company or law enforcement authorities.
                  </p>
                  <h4 className='fw-bold'>
                  7. Disclaimer of Warranties, Limitations of Liability and Indemnification
                  </h4>
                  <p>
                  <b>Your use of iRegisterkids is at your sole risk. The service is provided "as is" and
                   "as available".</b> We disclaim all warranties of any kind, express or implied, including, 
                   without limitation, the warranties of merchantability, fitness for a particular purpose
                    and non-infringement. We are not liable for damages, direct or consequential, resulting 
                    from your use of the Website, and you agree to defend, indemnify and hold us harmless
                     from any claims, losses, liability costs and expenses (including but not limited to attorney's fees)
                     arising from your violation of any third-party's rights.
                  You acknowledge that you have only a limited, non-exclusive, nontransferable license 
                  to use the Website. 
                  <br />
                  <br />
                  Because the Website is not error or bug free, you agree that you will 
                  use it carefully and avoid using it in ways which might result in any loss of your or
                   any third party's property or information.
                  While iRegisterkids attempts to make your access to and use of the Website safe, we
                   do not represent or warrant that the Site or the Content are free of viruses or other harmful components.
                  </p>  
                  <h3 className='fw-bold'>
                  8. Governing Law and Jurisdiction
                  </h3>
                  <p>
                  These Terms of Service and any dispute or claim arising out of, or related 
                  to them, shall be governed by and construed in accordance with the internal 
                  laws of the Province of Ontario without giving effect to any choice or conflict of law provision or rule.
                  Any legal suit, action or proceeding arising out of, or related to, these 
                  Terms of Service or the Website shall be instituted exclusively in the federal 
                  courts of Canada or Province of Ontario. 
                  <br />
                  <br />
                  If you choose to access the Website from locations other than Ontario, you will be responsible for compliance with all
                   local laws of such other jurisdiction and you agree to indemnify iRegisterkids
                    for your failure to comply with any such laws.

                  </p>
                  <h3 className='fw-bold'>
                  9. Waiver and Severability
                  </h3>
                  <p>
                  Our failure to exercise or enforce any right or provision of the Terms of Service
                   shall not constitute a waiver of such right or provision. The Terms of Service
                    constitutes the entire agreement between you and iRegisterkids and govern your 
                    use of the service, superseding any prior agreements (including, but not limited
                     to, any prior versions of the Terms of Service).
                     <br/>
                     <br />
                     If any provision of these Terms 
                     of Service is held by a court of competent jurisdiction to be invalid, illegal or
                      unenforceable for any reason, such provision shall be eliminated or limited to the minimum 
                  extent such that the remaining provisions of the Terms of Service will continue in full force and effect.
                  </p>  
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsOfUseDetails;
