import React from 'react';
import PageHeader from '../components/common/PageHeader';
import PageMeta from '../components/common/PageMeta';
import Contact from '../components/contact/Contact';
import ContactUsForm from '../components/contact/ContactUsForm';
import Footer from '../layout/Footer/Footer';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const ContactPage = () => {
  return (
    <Layout>
      <PageMeta title='Contact Us' />
      <Navbar />
      <div style={{
            background:
              "url('assets/img/contact-page-banner.png')no-repeat center center / cover" , color:"#fff"
          }}>
      <PageHeader
        title='Contact Us'
        desc='Get in touch with us.'
      />
      </div>
      <Contact />
      <ContactUsForm />
      <Footer
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default ContactPage;
