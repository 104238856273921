import Routes from './routers/Routes';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';

//Modal Video
import 'react-modal-video/scss/modal-video.scss';

const App = () => {
  return (
    <>
      <Routes />
        {/* Tawk chat integration */}
        <TawkMessengerReact
        propertyId="63463b3f54f06e12d899b13f"
       widgetId="default"/>
      
    </>
  );
};

export default App;
 