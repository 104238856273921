import React from 'react';
import PageMeta from './common/PageMeta';
import DownlaodApp from './features/DownlaodApp';
import AppFeatureOne from './features/FeatureOne';
import AppFeatureTwo from './features/FeatureTwo';
import WhyiRegisterKids from './features/WhyiRegisterKids';
import PromoVideo from './PromoVideo';
import Footer from '../layout/Footer/Footer';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import Banner from './Banner';

const Home = () => {
  return (
    <Layout>
      <PageMeta title='iRegisterkids' />
      <Navbar navDark />
      <Banner />
      <WhyiRegisterKids />
      <AppFeatureOne />
      <AppFeatureTwo />
      <DownlaodApp />
      <PromoVideo />
      <Footer />
    </Layout>
  );
};

export default Home;
