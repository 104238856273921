import React from 'react';
import Fade from 'react-reveal/Fade';
const FeatureTwo = () => {
  return (
    <>
      <section className='app-two-feature-three bg-light ptb-120'>
        <div className='container'>
     
          <div className='row align-items-center justify-content-lg-between'>
          <Fade up>
            <div className='col-lg-5 col-xl-5 col-sm-12'>
              <div className='app-two-feature-three-left'>
                <div className='app-content-feature-wrap'>
                <h4 className='h6'>For childcare centres/organization with kids</h4>
                  <h2>System Management</h2>
                  <p>
                  Parents and Carers can keep track of children's attendance and their
                   daily activities. Generate a detailed report anytime.
                  </p>
                </div>
                <div className='app-two-feature-two-content'>
                <h4 className='h6'>What you get</h4>
                  <ul className='list-unstyled d-flex flex-wrap list-two-col mt-2'>
                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                    Attendance management and reporting that eliminates pen and paper 
                    </li>
                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                     Support online parent pre-registration that eliminates excess waiting time
                    </li>
                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                      Staff Clock-In&Out Time Tracking using bio-metric
                    </li>
                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                      Unlimited registration for both students, parents/guardians and users
                    </li>
                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                      Online parent portal eliminating unnecessary application installation
                    </li>
                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                     Guess what! No hidden charges or functionality limitation
                    </li>
                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                     Publish announcement or news on kiosk systems
                    </li>
                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                    Desktop application self update without users interaction
                    </li>
                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                 Support real time screen update of kids requested for check out from the kiosk system
                    </li>
                  </ul>
                </div>
             </div>
             </div>
             </Fade>
             <Fade up>
            <div className='col-lg-6 col-xl-6 col-sm-12'>
              <div className=' app-two-feature-three-img position-relative text-center mt-5 mt-lg-0'>
               
                <img
                  src='assets/img/management.png'
                  alt='app screen'
                  className='position-relative z-5 golden-border'
                />
              </div>
            </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureTwo;
