import React from 'react';
import { Link } from 'react-router-dom';

const PolicyDetails = () => {
  return (
    <>
      <section className='ptb-120'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-lg-4 col-md-4 d-none d-md-block d-lg-block'>
              <div className='sticky-sidebar'>
                <div className='bg-light p-5 mt-4 rounded-custom'>
                  <span
                    className='text-decoration-none text-muted d-flex align-items-center py-2'
                  >
                    <div className='quick-support-icon rounded-circle bg-primary-soft me-3'>
                      <i className='far fa-envelope text-primary'></i>
                    </div>
                    <div className='contact-option-text'>
                      info@iregisterkids.com
                    </div>
                  </span>
                  <Link
                    to='/contact-us'
                    target='_blank'
                    className='text-decoration-none text-muted d-flex align-items-center py-2'
                  >
                    <div className='quick-support-icon rounded-circle bg-danger-soft me-3'>
                      <i className='far fa-comment-alt-lines text-danger'></i>
                    </div>
                    <div className='contact-option-text'>Contact Us</div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-7 col-md-8'>
              <div className='tab-content'>
                <div
                  className='tab-pane fade show active'
                  role='tabpanel'
                >
                  <div>
                    <h6>This privacy notice describes how we might use your information if you:</h6>
                    - Visit our website at https://iregisterkids.com
                    <p>- Download and use our mobile application — iRegisterkids Apps</p>
                    <p>-Engage with us in other related ways ― including any sales, marketing, or events</p>
                    <p>
                   In this privacy notice, if we refer to:
                    -"Website," we are referring to any website of ours that links to this policy
                  -"App," we are referring to any application of ours that link to this policy, including any listed above
                 "Services," we are referring to our Website, App, and other related services, including any sales, marketing, or events
                 The purpose of this privacy notice is to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.

           Please read this privacy notice carefully, as it will help you understand what we do with the information that we collect.
                    </p>
                 
                    <ul className='list-unstyled mt-4'>
                      <li className='py-4 border-top border-light'>
                          <h3 className='h5'>
                          1. WHAT INFORMATION DO WE COLLECT?
                          </h3>
                          <h6>Personal information you disclose to us</h6>
                          <p>
                          In Short:  We collect personal information that you provide to us.
                         We collect personal information that you voluntarily provide to us 
                         when you register on the Services, express an interest in obtaining
                          information about us or our products and Services,
                          when you participate in activities on the Services or otherwise 
                          when you contact us.
                         The personal information that we collect depends on the
                          context of your interactions with us and the Services, 
                          the choices you make and the products and features you use. 
                          The personal information we collect may include the following:
                          <br />
                          <b>Personal Information Provided by You </b>.<br /> We collect names; phone 
                          numbers; email addresses; mailing addresses; passwords; and other 
                          similar information.
                          <br />
                          <b>Payment Data.</b> <br /> We may collect data necessary to process your payment 
                          if you make purchases, such as your payment instrument number 
                          (such as a credit card number), and the security code associated 
                          with your payment instrument. All payment data is stored by Stripe. 
                          You may find their <a href="https://stripe.com/gb/privacy"> privacy notice link(s)</a>
                          <br />
                         All personal information that you provide to us must be true, 
                         complete and accurate, and you must notify us of any changes 
                         to such personal information.
                         <br />
                          <b>Information collected through our App</b><br />
                          <i>In Short:  We collect information regarding your mobile device, push notifications, when you use our App.</i><br />
                          If you use our App, we also collect the following information:
                          <br />
                          - Mobile Device Data. We automatically collect device information 
                          (such as your mobile device ID, model and manufacturer), 
                          operating system, version information and system configuration 
                          information, device and application identification numbers, 
                          browser type and version, hardware model Internet service 
                          provider and/or mobile carrier, and Internet Protocol (IP)
                          address (or proxy server). 
                          <br />
                          <br />
                          If you are using our App, we may
                          also collect information about the phone network associated
                          with your mobile device, your mobile device’s operating system 
                          or platform, the type of mobile device you use, your mobile 
                          device’s unique device ID and information about the features of our App you accessed.
                        	Push Notifications. We may request to send you push
                           notifications regarding your account or certain 
                           features of the App.
                           <br />
                            If you wish to opt-out from 
                           receiving these types of communications, 
                           you may turn them off in your device's settings.
                           <br />
                           This information is primarily needed to maintain the security and 
                           operation of our App, for troubleshooting and for our 
                           internal analytics and reporting purposes.

                          </p>
                      </li>
                      <li className='py-4 border-top border-light'>
                          <h3 className='h5'>
                          2. HOW DO WE USE YOUR INFORMATION?
                          </h3>
                          <p>
                          <i>In Short:  We process your information for 
                          purposes based on legitimate business interests, 
                          the fulfillment of our contract with you, 
                          compliance with our legal obligations, and/or your consent.</i>
                          We use personal information collected via our Services for 
                          a variety of business purposes described below.
                          <br />
                          <br />
                           We process your personal information for these
                            purposes in reliance on our legitimate business 
                            interests, in order to enter into or perform a 
                            contract with you, with your consent, and/or for
                             compliance with our legal obligations. 
                             We indicate the specific processing grounds 
                             we rely on next to each purpose listed below.

                             We use the information we collect or receive:
                             Fulfill and manage your orders. We may use your
                               information to fulfill and manage your orders, payments, 
                               returns, and exchanges made through the Services.
                               
	                         Administer prize draws and competitions. 
                              We may use your information to administer prize 
                              draws and competitions when you elect to participate in our competitions.
                            
                            To deliver and facilitate delivery of services to the user. 
                              We may use your information to provide you with the requested service.
                            To respond to user inquiries/offer support to users. 
                              We may use your information to respond to your inquiries
                              and solve any potential issues you might have with the use of our Services.
                         
                          </p>
                      </li>
                      <li className='py-4 border-top border-light'>
                          <h3 className='h5'>
                          3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                          </h3>
                          <p>
                          <i><b>In Short:</b>  We only share information with your consent, to comply with laws,
                           to provide you with services, to protect your rights, or to fulfill business obligations.</i>
                           We may process or share your data that we hold based on the following legal basis:
                          
                     <b>Consent:</b> We may process your data if you have given us specific 
                      consent to use your personal information for a specific purpose.
                    
                      <b>Legitimate Interests:</b> We may process your data when it is reasonably
                        necessary to achieve our legitimate business interests.
                     
                   <b>Performance of a Contract:</b> Where we have entered into a contract with you, we may process
                     your personal information to fulfill the terms of our contract.
                    
                   <b>Legal Obligations:</b> We may disclose your information where we are 
                      legally required to do so in order to comply with applicable law, 
                      governmental requests, a judicial proceeding, court order, or legal
                      process, such as in response to a court order or a subpoena 
                    (including in response to public authorities to meet national 
                    security or law enforcement requirements).
                   
                   <b>Vital Interests:</b> We may disclose your information where we believe 
                    it is necessary to investigate, prevent, or take action regarding
                    potential violations of our policies, suspected fraud, situations involving potential threats to the safety of 
                    any person and illegal activities, or as evidence in litigation in which we are involved.
                  
                  <b>More specifically, we may need to process your data or share
                    your personal information in the following situations:</b>
                 Business Transfers. We may share or transfer your information in 
                  connection with, or during negotiations of, any merger, sale of company assets, 
                  financing, or acquisition of all or a portion of our business to another company
                  
                  
                   </p>
                      </li>
                      <li className='py-4 border-top border-light'>
                       
                          <h3 className='h5'>
                          4. HOW LONG DO WE KEEP YOUR INFORMATION?
                          </h3>
                          <p>
                          <i><b>In Short:</b>  We keep your information for as long as necessary to fulfill
                           the purposes outlined in this privacy notice unless otherwise required by law.</i>
                        <br/>
                        <br/>
                         We will only keep your personal information for as long as it is
                          necessary for the purposes set out in this privacy notice,
                           unless a longer retention period is required or permitted by law
                          (such as tax, accounting or other legal requirements). 
                          <br/>
                          No purpose in this notice will require us keeping your 
                         personal information for longer than the period of time in which users have an account with us.
                         <br/>
                         <br/>
                         When we have no ongoing legitimate business need to process your personal
                          information, we will either delete or anonymize such information, 
                          or, if this is not possible (for example, because your personal 
                          information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.

                          </p>
                         
                      </li>
                      <li className='py-4 border-top border-light'>
                       
                          <h3 className='h5'>
                          5. HOW DO WE KEEP YOUR INFORMATION SAFE?
                          </h3>
                          <p>
                          <i><b>In Short:</b> We aim to protect your personal information through a
                           system of organizational and technical security measures.</i>
                           <br />
                           <br />
                          We have implemented appropriate technical and organizational
                          security measures designed to protect the security of any personal
                          information we process. However, despite our safeguards and efforts
                           to secure your information, no electronic transmission over the
                           <br />
                           Internet or information storage technology can be guaranteed to
                            be 100% secure, so we cannot promise or guarantee that hackers, 
                            cybercriminals, or other unauthorized third parties will not be 
                            able to defeat our security, and improperly collect, access, steal, 
                            or modify your information. 
                            <br />
                           <br />
                            Although we will do our best to protect 
                            your personal information, transmission of personal information to 
                            and from our Services is at your own risk. You should only access the Services within a secure environment.


                          </p>
                         
                      </li>
                      <li className='py-4 border-top border-light'>
                       
                          <h3 className='h5'>
                          6. DO WE COLLECT INFORMATION FROM MINORS?
                          </h3>
                          <p>
                          <i><b>In Short:</b>  We do not knowingly collect data from or market to children under 18 years of age.
                          </i>
                          <br />
                          We do not knowingly solicit data from or market to 
                          children under 18 years of age. By using the Services, 
                          you represent that you are at least 18 or that you are the 
                          parent or guardian of such a minor and consent to such minor 
                          dependent’s use of the Services.
                          <br />
                          <br />
                           If we learn that personal
                          information from users less than 18 years of age has been 
                          collected, we will deactivate the account and take reasonable
                           measures to promptly delete such data from our records.
                          If you become aware of any data we may have collected
                          from children under age 18, please contact us at info@iregisterkids.com.

                          </p>
                         
                      </li>
                      <li className='py-4 border-top border-light'>
                        
                          <h3 className='h5'>
                          7. WHAT ARE YOUR PRIVACY RIGHTS?
                          </h3>
                          <p>
                          <i><b>In Short:</b> You may review, change, or terminate your account at any time.</i>
                          <br/>
                       If you are a resident in the EEA or UK and you believe we are unlawfully processing your
                        personal information, you also have the right to complain to your local data protection 
                        supervisory authority. 
                        <br/>
                        You can find their contact details here: https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                       If you are a resident in Switzerland, the contact details for 
                       the data protection authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.
                       <br/>
                     If you have questions or comments about your privacy rights, you may email us at info@iregisterkids.com.
                     <br/>
                     <br/>
                      <b>Account Information</b>
                     If you would at any time like to review or change the information in your account or terminate your account, you can:
                <br/>
                  - Log in to your account settings and update your user account.
                    Upon your request to terminate your account, we will deactivate or delete your
                     account and information from our active databases.
                     <br/>
                     <br/>
                      However, we may retain
                      some information in our files to prevent fraud, troubleshoot problems,
                       assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
                       <br/>
                       <br/>
                      <b>Opting out of email marketing:</b> <br/> You can unsubscribe from our marketing
                  email list at any time by clicking on the unsubscribe link in the 
                  emails that we send or by contacting us using the details 
                  provided below. 
                  <br/>
                  <br/>
                  You will then be removed from the marketing 
                  email list — however, we may still communicate with you, for
                   example to send you service-related emails that are necessary 
                   for the administration and use of your account, to respond 
                   to service requests, or for other non-marketing purposes. 
                   To otherwise opt-out, you may:
                   Access your account settings and update your preferences.
                      </p>
                         
                      </li>
                      <li className='py-4 border-top border-light'>
                        
                          <h3 className='h5'>
                          8. CONTROLS FOR DO-NOT-TRACK FEATURES
                          </h3>
                          <p>
                          Most web browsers and some mobile operating systems and mobile
                           applications include a Do-Not-Track ("DNT") feature or setting
                            you can activate to signal your privacy preference not to have
                             data about your online browsing activities monitored and collected.
                             <br/>
                             <br/>
                              At this stage no uniform technology standard for recognizing and 
                              implementing DNT signals has been finalized. As such, we do not 
                              currently respond to DNT browser signals or any other mechanism 
                              that automatically communicates your choice not to be tracked 
                              online. If a standard for online tracking is adopted that we
                               must follow in the future, we will inform you about that
                                practice in a revised version of this privacy notice. 
                          </p>
                          
                      </li>
                      <li className='py-4 border-top border-light'>
                        
                          <h3 className='h5'>
                          9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                          </h3>
                          <p>
                          <i><b>In Short:</b>
                          <br/>
                          Yes, if you are a resident of California, you are granted specific rights
                           regarding access to your personal information.</i>
                          California Civil Code Section 1798.83, also known as the "Shine The Light" 
                          law, permits our users who are California residents to request and obtain 
                          from us, once a year and free of charge, information about categories of
                           personal information (if any) we disclosed to third parties for direct 
                           <br/>
                           <br/>
                           marketing purposes and the names and addresses of all third parties with
                            which we shared personal information in the immediately preceding calendar year.
                             If you are a California resident and would like to make such a request, 
                             please submit your request in writing to us using the contact information provided below.

                          If you are under 18 years of age, reside in California, and have a registered
                         account with a Service, you have the right to request removal of unwanted data
                          that you publicly post on the Services. 
                          <br/>
                          <br/>
                          To request removal of such data, please contact us using the contact information provided below, and 
                          include the email address associated with your account and a statement 
                          that you reside in California. We will make sure the data is not publicly 
                          displayed on the Services, but please be aware that the data may not be 
                          completely or comprehensively removed from all our systems (e.g. backups, etc.).
                          <br/>
                          <br/>
                     <b>CCPA Privacy Notice:</b>
                     <br/>
                    The California Code of Regulations defines a "resident" as:
                    <br/>
                    (1) every individual who is in the State of California for other than a temporary or transitory purpose and
                    <br/>
                    (2) every individual who is domiciled in the State of California who is outside 
                    the State of California for a temporary or transitory purpose
                    All other individuals are defined as "non-residents."
                    If this definition of "resident" applies to you, we must adhere to certain rights
                     and obligations regarding your personal information.
                    What categories of personal information do we collect?
                    We have collected the following categories of personal information in the past twelve (12) months:
                    <br />
                    <br />

                    We may also collect other personal information outside of these categories instances where you interact with us in-person, online, or by phone or mail in the context of:
                    Receiving help through our customer support channels;
                    Participation in customer surveys or contests; and
                    Facilitation in the delivery of our Services and to respond to your inquiries.
                    How do we use and share your personal information?

                    More information about our data collection and sharing practices can be found in this privacy notice.
                    You may contact us by email at info@iregisterkids.com, <a href="https://iregisterkids.com/contact">by visiting </a> 
                    or by referring to the contact details at the bottom of this document.
                    <br />
                    <br />
                    If you are using an authorized agent to exercise your right to opt-out we may deny a request 
                    if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.

                    Will your information be shared with anyone else?
                    We may disclose your personal information with our service providers pursuant 
                    to a written contract between us and each service provider. Each service provider
                    is a for-profit entity that processes the information on our behalf.

                    We may use your personal information for our own business purposes, such as for 
                    undertaking internal research for technological development and demonstration. 
                    This is not considered to be "selling" of your personal data.
                    iGeeksNG Solution has not disclosed or sold any personal information to third parties for
                    a business or commercial purpose in the preceding 12 months. 
                    <br />
                    <br />iGeeksNG Solution will not sell personal information in the future belonging to website visitors, users and other consumers.
                    Your rights with respect to your personal data Right to request deletion of the data - Request to delete
                    You can ask for the deletion of your personal information. 
                    If you ask us to delete your personal information, we will respect 
                    your request and delete your personal information, subject to certain 
                    exceptions provided by law, such as (but not limited to) the exercise 
                    by another consumer of his or her right to free speech, our compliance 
                    requirements resulting from a legal obligation or any processing that may
                    be required to protect against illegal activities.

                  Right to be informed - Request to know
                  Depending on the circumstances, you have a right to know:
                  whether we collect and use your personal information;
                  the categories of personal information that we collect;
                  the purposes for which the collected personal information is used;
                  whether we sell your personal information to third parties;
                  the categories of personal information that we sold or disclosed for a business purpose;
                  the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and
                  the business or commercial purpose for collecting or selling personal information.
                  <br />
                  <br />
                  In accordance with applicable law, we are not obligated to
                  provide or delete consumer information that is de-identified 
                  in response to a consumer request or to re-identify individual data to verify a consumer request.

                  Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
                  We will not discriminate against you if you exercise your privacy rights. Verification process
                Upon receiving your request, we will need to verify your identity to 
                determine you are the same person about whom we have the information in our system.
                <br/>
                <br/>
                These verification efforts require us to ask you to provide information so 
                that we can match it with information you have previously provided us. 
                For instance, depending on the type of request you submit, 
                we may ask you to provide certain information so that we can 
                match the information you provide with the information we already have on file, 
                or we may contact you through a communication method (e.g. phone or email) 
                that you have previously provided to us. We may also use other verification methods as the circumstances dictate.

                We will only use personal information provided in your request 
                to verify your identity or authority to make the request.
                <br/> 
                <br/> 
                To the extent possible, we will avoid requesting additional information 
                from you for the purposes of verification. If, however, 
                we cannot verify your identity from the information already
                maintained by us, we may request that you provide additional 
                information for the purposes of verifying your identity, 
                and for security or fraud-prevention purposes. We will delete
                  such additionally provided information as soon as we finish verifying you.
                  <br/>
                  <br/>
                Other privacy rights you may object to the processing of your personal data
                  you may request correction of your personal data if it is 
                incorrect or no longer relevant, or ask to restrict the processing of the data
                  you can designate an authorized agent to make a request under
                the CCPA on your behalf. We may deny a request from an authorized 
                agent that does not submit proof that they have been validly 
                authorized to act on your behalf in accordance with the CCPA.
                you may request to opt-out from future selling of your personal 
                information to third parties. Upon receiving a request to opt-out,
                we will act upon the request as soon as feasibly possible, but no
                later than 15 days from the date of the request submission.
                <br />
                <br/>
                To exercise these rights, you can contact us by email at info@iregisterkids.com, 
               <a href="https://iregisterkids.com/contact">by visiting</a> or by referring to the contact details 
                at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you.  
                    </p>   
                      </li>
                      <li className='py-4 border-top border-light'>
                        
                        <h3 className='h5'>
                        10. DO WE MAKE UPDATES TO THIS NOTICE?     
                        </h3>
                        <p>
                        <i><b>In Short:</b>Yes, we will update this notice as necessary to stay compliant with relevant laws.</i>
                        We may update this privacy notice from time to time. The updated version will be indicated by an updated 
                        "Revised" date and the updated version will be effective as soon as it is accessible. If we make material 
                        changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. 
                        We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                        <br/>
                        </p>
                        </li>
                        <li className='py-4 border-top border-light'>
                        <h3 className='h5'>
                        11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?    
                        </h3>
                        <p>
                        If you have questions or comments about this notice, you may email us at info@iregisterkids.com or by post to:
                        iGeeksNG Solution 6137 Crawfordsville Road Suite F, #361 Speedway IN 46224. USA
                        <br/>
                        </p>
                        </li>
                        <li className='py-4 border-top border-light'>
                        <h3 className='h5'>
                        12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?     
                        </h3>
                        <p>
                        Based on the applicable laws of your country, you may 
                        have the right to request access to the personal information we 
                        collect from you, change that information, or delete it in some circumstances. 
                        To request to review, update, or delete your
                         personal information, <a href ="https://iregisterkids.com">please visit</a> an contact us.

                        <br/>
                        </p>
                        </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PolicyDetails;
