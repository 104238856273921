import React from 'react';

const PrivacyHeader = () => {
  return (
    <>
      <section
      style={{
        background:
          "url('assets/img/policy1.png')no-repeat center center / cover",
      }}
        className='page-header position-relative overflow-hidden ptb-120'
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-12'>
              <div className='text-center'>
                <h1  style={{color:'#fff'}} className='display-5 fw-bold'>Privacy Policy</h1>
                <p style={{color:'#fff'}} >Thank you for choosing to be part of our community at iGeeksNG Solution ("Company," "we," "us," or "our"). We are committed to protecting 
                your personal information and your right to privacy.
                If you have any questions or concerns about this privacy notice or our practices with regard to your personal 
                information, please contact us at info@iregisterkids.com.
                </p>
                <div className='form-block-banner mw-60 m-auto mt-5'>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyHeader;
