import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import base_url from "../../api/config";
import FormItem from "../common/FormItem";

const defaultForm = {
  companyName: "",
  phoneNumber: "",
  emailAddress: "",
  noBranches: "",
  websiteUrl: "",
};

const validationSchema = yup.object({
  companyName: yup.string(),
  phoneNumber: yup.string(),
  emailAddress: yup.string().email().required("Email address is required"),
  noBranches: yup.string(),
  websiteUrl: yup.string(),
});

const RequestDemoForm = () => {
  const [isLoading, setIsloading] = useState(false);
  const handleSubmit = async (data, formikBag) => {
    try {
      setIsloading(true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const result = await axios.post(
        `${base_url}forms/form_05`,
        {
          companyName: "iRegisterkids Solution",
          adminEmailAddress: "info@iregisterkids.com",
          data: {
            emailAddress: data.emailAddress,
            serializedJsonData: JSON.stringify(data),
          },
        },
        config
      );
      setIsloading(false);
      toast.success(result.data.message);
      formikBag.resetForm();
    } catch (error) {
      setIsloading(false);
      toast.error(
        error.response && error.response.data?.message
          ? error.response.data.message
          : error.message || "Error submitting form"
      );
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: defaultForm,
    onSubmit: handleSubmit,
  });
 
  return (
    <>
      <ToastContainer position="bottom-center" limit={1} />
      <div className="col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1">
        <div className="register-wrap p-5 bg-white shadow mt-5 mt-lg-0 mt-xl-0">
          <h3 className="fw-medium h4">Request A Demo</h3>

          <form
            onSubmit={formik.handleSubmit}
            className="mt-4 pt-5 pb-5 register-form"
          >
            <div className="row">
              <div className="col-sm-6 ">
                <div className="input mb-3">
                  <FormItem error={formik.errors.companyName}>
                    <input
                      disabled={isLoading}
                      type="text"
                      id="companyName"
                      name="companyName"
                      className="form-control"
                      placeholder="Company Name"
                      aria-label="companyName"
                      value={formik.values.companyName}
                      onChange={formik.handleChange}
                    />
                  </FormItem>
                </div>
              </div>

              <div className="col-sm-6 ">
                <div className="input mb-3">
                  <FormItem error={formik.errors.phoneNumber}>
                    <input
                      disabled={isLoading}
                      type="text"
                      id="phoneNumber"
                      name="phoneNumber"
                      className="form-control"
                      placeholder="Phone Number"
                      aria-label="phoneNumber"
                      value={formik.values.phoneNumber}
                      onChange={formik.handleChange}
                    />
                  </FormItem>
                </div>
              </div>

              <div className="col-sm-6 ">
                <div className="input mb-3">
                  <FormItem error={formik.errors.emailAddress}>
                    <input
                      required
                      disabled={isLoading}
                      type="email"
                      id="emailAddress"
                      name="emailAddress"
                      className="form-control"
                      placeholder="Email Address"
                      aria-label="email"
                      value={formik.values.emailAddress}
                      onChange={formik.handleChange}
                    />
                  </FormItem>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="input mb-3">
                  <FormItem error={formik.errors.noBranches}>
                    <select
                      disabled={isLoading}
                      value={formik.values.noBranches}
                      onChange={formik.handleChange}
                      className="form-control form-select"
                      id="noBranches"
                      name="noBranches"
                      data-msg="Please select your number of branches your organization have."
                    >
                      <option value="None selected">No. of branches</option>
                      <option value="1 branch">1 branch</option>
                      <option value="2 - 10 branches">2 - 10 branches</option>
                      <option value="More than 10 branches">More than 10 branches</option>
                    </select>
                  </FormItem>
                </div>
              </div>

              <div className="col-sm-12 ">
                <div className="input mb-3">
                  <FormItem error={formik.errors.websiteUrl}>
                    <input
                      disabled={isLoading}
                      type="text"
                      id="websiteUrl"
                      name="websiteUrl"
                      className="form-control"
                      placeholder="Website Url"
                      aria-label="websiteUrl"
                      value={formik.values.websiteUrl}
                      onChange={formik.handleChange}
                    />
                  </FormItem>
                </div>
              </div>
              <div className="col-12">
                <button
                  disabled={isLoading}
                  type="submit"
                  className="btn btn-primary mt-4"
                >
                  {isLoading ? "Sending..." : " Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default RequestDemoForm;
