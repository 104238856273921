import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import PrivacyHeader from '../../components/PrivacyPolicy/PrivacyHeader';
import FooterOne from '../../layout/Footer/Footer';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import PolicyDetails from '../../components/PrivacyPolicy/PolicyDetails';

const Privacy = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <PrivacyHeader />
      <PolicyDetails />
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.sv')no-repeat bottom left",
        }}
      />
    </Layout>
  );
};

export default Privacy;
