import React from 'react';
import Fade from 'react-reveal/Fade';

const FeatureOne = () => {
  return (
    <>
      <section className='app-two-feature-two pt-60 pb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
          <Fade left>
            <div className='col-lg-6 col-xl-6 col-md-12'>
              <div className='app-two-feature-two-img'>
                <img
                  src='assets/img/featureImg246.png'
                  alt='feature'
                  className='img-fluid'
                />
              </div>
            </div>
            </Fade>
            <Fade right>
            <div className='col-xl-5 col-lg-5'>
              <div className='app-two-feature-two-right'>
                <div className='feature-content-wrap'>
                  <h4 className='h6'>For parents and guardians</h4>
                  <h2>Clock IN & OUT System</h2>
                  <p>
                  Kids check-in/out that eliminates long queues and waiting periods.
                  </p>
                </div>
                <div className='app-two-feature-two-content'>
                <h4 className='h6'>What you get</h4>
                  <ul className='list-unstyled d-flex flex-wrap list-two-col mt-2'>
                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                     kids daily attendance tracking and notification
                    </li>

                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                    PIN code or bio-metric for kid's guardian verification upon check in/out.
                    </li>

                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                    Track which authorized member checked in/out your kids.
                    </li>
                    
                    
                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                    Guess what! No application required to be installed on your device.</li>


                    <li className='py-1'>
                      <i style={{color:'#0A2B66'}} className='fa fa-check-circle me-2'></i>
                    Easily manage member you want to give access to check in/out your kids</li>


                  </ul>
                </div>
              </div>
            </div>
            </Fade>
          </div>
          
        </div>
      </section>
    </>
  );
};

export default FeatureOne;
