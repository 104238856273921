import React from 'react';
import { Link } from 'react-router-dom';

const Pricing = ({ paddingTop }) => {
  return (
    <>
      <section className={`pricing-section ${paddingTop} position-relative z-2`}>
        <div className='container'>
        <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div
                className='
                  position-relative
                  single-pricing-wrap
                  rounded-custom
                  bg-white
                  custom-shadow
                  p-5
                  mb-4 mb-lg-0
                '
              >
                <div className='pricing-header mb-32'>
                  <h3 className='package-name text-primary d-block'>Starter</h3>                   
                  <h4 className='display-6 fw-semi-bold'>
                    $10<span>/month</span>
                  </h4>
                </div>
                <div className='pricing-info mb-4'>
                  <ul className='pricing-feature-list list-unstyled'>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>{' '}
                      Unlimited access to all product and major features
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>{' '}
                      Unrestricted access to all new
                       updates at no extra cost.
                    </li> 
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      Only support 1 organization branch
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      Only support Unlimited kids check Ins & Outs
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      Notification is only restricted to email
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>
                      No hidden charges.
                    </li>
                  </ul>
                </div>
                <Link
                  to='/request-for-demo'
                  className='btn btn-outline-primary mt-2'
                >
                  Request Demo
                </Link>

                {/* <!--pattern start--> */}
                <div
                  className='
                    dot-shape-bg
                    position-absolute
                    z--1
                    left--40
                    bottom--40
                  '
                >
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div
              style={{backgroundColor: '#0a2b66'}}
                className='
                  position-relative
                  single-pricing-wrap
                  rounded-custom
                  text-white
                  p-5
                  mb-4 mb-lg-0
                '
              >
                <div className='pricing-header mb-32'>
                  <h3 style={{color: '#FF6321'}} className='package-name d-block'>
                    Advanced
                  </h3>
                  <h4 style={{color: '#fff'}}  className='display-6 fw-semi-bold'>
                    $30<span>/month</span>
                  </h4>
                </div>
                <div className='pricing-info mb-4'>
                  <ul className='pricing-feature-list list-unstyled'>
                    <li>
                      <i className='fas fa-circle fa-2xs text-warning me-2'></i>{' '}
                      Unlimited access to all product and major features
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-warning me-2'></i>{' '}
                      Unrestricted access to all new updates at no extra cost.
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-warning me-2'></i>
                      Real-Time Feedback
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-warning me-2'></i>
                     Support up to 10 organization branches
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-warning me-2'></i>
                      Notification is supported both on text and email with text restricted to 400 counts
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-warning me-2'></i>
                     Support Unlimited staff and kids check Ins & Outs
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-warning me-2'></i>
                      No hidden charges.
                    </li>
                  </ul>
                </div>
                <Link to='/request-for-demo' className='btn btn-primary mt-2'>
                Request Demo
                </Link>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div
                className='
                  position-relative
                  single-pricing-wrap
                  rounded-custom
                  bg-white
                  custom-shadow
                  p-5
                  mb-4 mb-lg-0
                '
              >
                <div className='pricing-header mb-32'>
                  <h3 className='package-name text-primary d-block'>Premium</h3>
                  <h4 className='display-6 fw-semi-bold'>
                    $100<span>/month</span>
                  </h4>
                </div>
                <div className='pricing-info mb-4'>
                  <ul className='pricing-feature-list list-unstyled'>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>{' '}
                     Everything supported by other packages
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>{' '}
                     Unlimited notification for both email and text
                    </li>
                    <li>
                      <i className='fas fa-circle fa-2xs text-primary me-2'></i>{' '}
                     Unlimited organization branches registration
                    </li> 
                  </ul>
                </div>
                <Link
                  to='/request-for-demo'
                  className='btn btn-outline-primary mt-2'
                >
                 Request Demo
                </Link>

                {/* <!--pattern start--> */}
                <div className='dot-shape-bg position-absolute z--1 right--40 top--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
                {/* <!--pattern end--> */}
              </div>
            </div>
          </div>
        </div>  
      </section>
    </>
  );
};
export default Pricing;
