import PageMeta from "../components/common/PageMeta"; 
import Footer from "../layout/Footer/Footer";
import Navbar from "../layout/Header/Navbar";
import Layout from "../layout/Layout";
import BookAppt from "../components/contact/RequestDemoForm";
import AboutHeader from '../components/common/AboutHeader';

const About = () => {
  return (
    <Layout>
      <PageMeta title="About Us" />
      <Navbar />
      <AboutHeader
        title='About Us'/>
         <div className='container mt-5'>
          <div className='row align-items-lg-center justify-content-between'>
            <div className='col-lg-5 order-lg-2 mb-7 mb-lg-0'>
              <div className='mb-4 mx-4' data-aos='fade-up'>
                <h2>About iRegisterkids</h2>
                <p>Not too much, not too little but precisely what you need for easy check-in and check-out system. We have built the best, simplified and affordable
                  system for childcare centers, elementary schools and other organizations handling kids.  
                  Work smart using our total solution that harnesses the power of cloud technology to secure and synchronize your data across your organization. 
                  </p>
              </div> 
              </div>
              <div className='col-lg-6 order-lg-1'>
                
              <div className='pr-lg-4 mt-md-4 position-relative'>
                <div
                  className='bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto'
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <img
                    src='assets/img/aboutImg.png'
                    alt='About'
                    className='img-fluid'
                  />
                </div>
              </div>
            </div> 
            </div> 
            </div> 
   <div className="d-flex justify-content-center mt-5 mb-5">
   <BookAppt />
   </div>
      <Footer />
    </Layout>
  );
};

export default About;
