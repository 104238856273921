import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import base_url from "../../api/config";
import FormItem from "../common/FormItem";

const defaultForm = {
  fullName: "",
  emailAddress: "",
  message: "",
};

const validationSchema = yup.object({
  fullName: yup.string().required("Full name is required"),
  emailAddress: yup.string().email().required("Email address is required"),
  message: yup.string().required("Message is required"),
});

const ContactUsForm = () => {
  const [isLoading, setIsloading] = useState(false);
  const handleSubmit = async (data, formikBag) => {
    console.log(data);
    // return;
    try {
      setIsloading(true);
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      const result = await axios.post(
        `${base_url}forms/form_05`,
        {
          companyName: "iRegisterkids Solution",
          adminEmailAddress: "info@iregisterkids.com",
          data: {
            emailAddress: data.emailAddress,
            serializedJsonData: JSON.stringify(data),
          },
        },
        config
      );
      console.log(result);
      setIsloading(false);
      toast.success(result.data.message);
      formikBag.resetForm();
    } catch (error) {
      setIsloading(false);
      console.log(error);
      toast.error(
        error.response && error.response.data?.message
          ? error.response.data.message
          : error.message || "Error submitting form"
      );
    }
  };

  const formik = useFormik({
    validationSchema,
    initialValues: defaultForm,
    onSubmit: handleSubmit,
  });

  console.log(formik);

  return (
    <>
      <ToastContainer position="bottom-center" limit={1} />
      <section
        className="contact-us-form pt-60 pb-120"
        style={{
          background:
            "url('assets/img/shape/contact-us-bg.svg')no-repeat center bottom",
        }}
      >
        <div className="container">
          <div className="row justify-content-lg-between align-items-center">
            <div className="col-lg-5 col-md-10">
              <div className="contact-us-img">
                <img
                  src="assets/img/contact.jpg"
                  alt="contact us"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-8 custom-shadow mt-5 mb-5 pb-5">
              <div className="section-heading pt-5">
                <h2 style={{ color: "#FF6321" }}>Send Us a message</h2>
                <p>You can send us a message using this form.</p>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="firstName" className="mb-1">
                      Full Name <span className="text-danger">*</span>
                    </label>
                    <div className="input mb-3">
                      <FormItem error={formik.errors.fullName}>
                        <input
                          required
                          disabled={isLoading}
                          type="text"
                          className="form-control"
                          id="fullName"
                          name="fullName"
                          placeholder="Full name"
                          aria-label="Full name"
                          value={formik.values.fullName}
                          onChange={formik.handleChange}
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="email" className="mb-1">
                      Email<span className="text-danger">*</span>
                    </label>
                    <div className="input mb-3">
                      <FormItem error={formik.errors.emailAddress}>
                        <input
                          required
                          disabled={isLoading}
                          type="email"
                          className="form-control"
                          id="emailAddress"
                          name="emailAddress"
                          placeholder="Email Address"
                          aria-label="Email"
                          value={formik.values.emailAddress}
                          onChange={formik.handleChange}
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="yourMessage" className="mb-1">
                      Message <span className="text-danger">*</span>
                    </label>
                    <div className="input mb-3">
                      <FormItem error={formik.errors.message}>
                        <textarea
                          required
                          disabled={isLoading}
                          className="form-control"
                          id="message"
                          name="message"
                          placeholder="How can we help you?"
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          style={{ height: "121px" }}
                        ></textarea>
                      </FormItem>
                    </div>
                  </div>
                </div>
                <button
                  disabled={isLoading}
                  type="submit"
                  className="btn btn-primary mt-5"
                >
                  {isLoading ? "Sending..." : " Send Message"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsForm;
