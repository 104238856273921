import React from "react";

const FormItem = ({ children, error }) => {
  return (
    <div>
      {children}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default FormItem;
