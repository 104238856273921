import React from 'react';
import Fade from 'react-reveal/Fade';
const WhyIregisterKids = () => {
  return (
    <>
      <section className='ptb-100'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-10'>
              <div className='section-heading text-center'>
                <h2>Benefits of iRegisterkids</h2>
              </div>
            </div>
          </div>
         
          <div className='row justify-content-center'>
       
            <div className='col-12 col-xl-10'>
          
              <div className='row'>
              <Fade up>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-one me-3 mb-4 mb-md-0'>
                      <i className='fa-solid fa-lock'></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Secure & Safe</h3>
                      <p>Only importance & basic information are collected.
                        <br/> 
                       All data are transmitted only through a secure and encrypted channel.
                      </p>
                    </div>
                  </div>
                </div>
                </Fade>
                <Fade up>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-two me-3 mb-4 mb-md-0'>
                      <i className='fa-solid fa-puzzle-piece-simple'></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Easy to use</h3>
                      <p>
                      No special training is required since it designed for a single purpose; which is keeping track of kids in your organization.
                      </p>
                    </div>
                  </div>
                </div>
                </Fade>
                <Fade up>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-three me-3 mb-4 mb-md-0'>
                      <i className='fa-solid fa-laptop-mobile'></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Device friendly</h3>
                      <p>
                      The kiosk system has been desgined to be available on Window PC, Android Devices & IOS Devices for easy access.
                      </p>
                    </div>
                  </div>
                </div>
                </Fade>
                <Fade up>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-four me-3 mb-4 mb-md-0'>
                      <i className='fa-solid fa-bell'></i>
                    </div>
                    <div>
                      <h3 className='h5'>Real Time Notification</h3>
                      <p>
                      Communication made easy with parents about their wards with real time sms & email notification.
                      </p>
                    </div>
                  </div>
                </div>
                </Fade>
              </div>
              
            </div>
            
          </div>
          
        </div>
        
      </section>
    </>
  );
};

export default WhyIregisterKids;
