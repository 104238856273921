import React from 'react';

const PageHeader = ({ title, desc }) => {
  return (
    <>
    <section
          className='page-header position-relative overflow-hidden ptb-120'
        >
          <div className='container'>
            <div>
              <div className='col-lg-8 col-md-12'>
                <h1 style={{color:'#ffffff'}} className='display-5 fw-bold'>{title}</h1>
                <p className='lead'>{desc}</p>
              </div>
            </div>
          </div>
        </section>
    </>
  );
};

export default PageHeader;
