import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import TermsOfUseDetails from '../../components/Terms/TermsOfUseDetails';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import Footer from '../../layout/Footer/Footer';

const TermsOfUse = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
      <TermsOfUseDetails />
      <Footer />
    </Layout>
  );
};

export default TermsOfUse;
