import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundScreen = () => {
  return (
    <>
      <section
        className='error-section ptb-120 bg-dark min-vh-100 w-100 d-flex flex-column justify-content-center'>
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div className='col-lg-5 col-md-6'>
              <div className='error-page-content-wrap'>
                <h1 className='display-5 fw-bold'> Page Not Found</h1>
                <p className='lead'>
                 The page you are looking for was not found !
                </p>
                <Link to='/' style={{background:"#FF6321", color:"#fff"}} className='btn mt-4'>
                  Go Back Home
                </Link>
              </div>
            </div>
          
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFoundScreen;
