import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <PageMeta />
      <Navbar />
    </Layout>
  );
};

export default PrivacyPolicy;
