  export const url = {
    prod: 'https://iregisterkids.com/api/',
    prod_sup: 'https://iregisterkids.com/prod_sup/api/',
    screen_app: 'https://screen.iregisterkids.com',
    kiosk: 'https://iregisterkids.com/api/updates/kiosk/kiosk.exe',
    apk:'https://play.google.com/store/apps/details?id=com.igeeksng.iregisterkidstab'
   };
    
   const  base_url = process.env.NODE_ENV === 'development' ? url.prod_sup : url.prod
   
   export default base_url;