import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import NotFoundScreen from "../components/NotFoundScreen";
import Pricing from "../pages/Pricing";
import ScrollToTop from "../components/common/ScrollToTop";
import ContactPage from "../pages/ContactPage";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Privacy from "../pages/PrivacyPolicy/Privacy";
import TermsOfUse from "../pages/TermsOfUse/TermsOfUse";
import RequestDemo from "../pages/RequestDemo";
import Home from "../components/Home";
import About from "../pages/About";
import Faq from "../pages/Faq";

class Routes extends Component {
  render() {
    return (
      <>
        <Router>
        <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about-us" component={About} />
            <Route exact path="/contact-us" component={ContactPage} />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/privacy-policy" component={Privacy} />
             <Route exact path="/terms-of-use" component={TermsOfUse} />
            <Route exact path="/request-for-demo" component={RequestDemo} />
            <Route component={NotFoundScreen} />
          </Switch>
        </Router>
      </>
    );
  }
}
export default Routes;
